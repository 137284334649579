<template>
  <b-form-row class="mb-2">
    <b-col cols="12">
      <b-form-row align-h="between" align-v="center">
        <b-col>
          <label class="font-weight-bold ft-s-18 mr-2"
            >คำถามข้อที่ {{ questionNo }}</label
          >

          <span>ประเภท: {{ getQuestionTypeName(question.question_type) }}</span>
        </b-col>

        <b-col class="text-right pt-2">
          <a href="javascript:void(0)" @click="$emit('remove')">
            <font-awesome-icon class="mx-auto" icon="window-close" />
          </a>
        </b-col>
      </b-form-row>
    </b-col>

    <b-col cols="12">
      <label
        class="font-weight-bold ft-s-14"
        :for="`question-${questionIndex}-title`"
        >คำถาม</label
      >

      <b-textarea
        :id="`question-${questionIndex}-title`"
        :state="
          invalid
            ? validation.$each[questionIndex].question_title.required
            : null
        "
        v-model="question.question_title"
        class="mb-2"
        rows="1"
        max-rows="4"
      >
      </b-textarea>

      <b-form-invalid-feedback :id="`question-${questionIndex}-title-feedback`">
        กรุณากรอกคำถาม
      </b-form-invalid-feedback>
    </b-col>

    <b-col v-if="question.question_type === 'choice'" cols="12">
      <label
        class="font-weight-bold ft-s-14 mt-2"
        :for="`question-${questionIndex}--choice-title`"
        >เพิ่มตัวเลือก</label
      >

      <template
        v-for="(questionChoice,
        questionChoiceIndex) in question.question_choices"
      >
        <b-form-row
          :key="`question-${questionIndex}--choice-${questionChoiceIndex}`"
        >
          <b-col>
            <b-input-group class="mt-3 input-group-seamless align-items-center">
              <!-- <template #append>
                    <b-input-group-text>
                      
                    </b-input-group-text>
                  </template> -->

              <b-form-input
                :id="
                  `question-${questionIndex}--choice-${questionChoiceIndex}-title`
                "
                :name="
                  `question-${questionIndex}--choice-${questionChoiceIndex}-title`
                "
                :state="
                  invalid
                    ? validation.$each[questionIndex].question_choices.$each[
                        questionChoiceIndex
                      ].question_choice_title.required
                    : null
                "
                v-model="
                  question.question_choices[questionChoiceIndex]
                    .question_choice_title
                "
              >
              </b-form-input>

              <a href="javascript:void(0)" class="ml-3">
                <font-awesome-icon
                  icon="window-close"
                  @click="removeChoice(questionChoiceIndex)"
                ></font-awesome-icon>
              </a>

              <b-form-invalid-feedback
                :id="
                  `question-${questionIndex}--choice-${questionChoiceIndex}-title-feedback`
                "
              >
                กรุณากรอกตัวเลือก
              </b-form-invalid-feedback>
            </b-input-group>
          </b-col>
        </b-form-row>
      </template>

      <b-form-row>
        <b-col>
          <b-button variant="primary" size="sm" class="mt-3" @click="addChoice"
            >เพิ่มคำตอบ</b-button
          >
        </b-col>
      </b-form-row>
    </b-col>
  </b-form-row>
</template>

<script>
export default {
  props: {
    questionIndex: Number,
    topicIndex: Number,
    vModel: Object,
    invalid: {
      type: Boolean,
      default: false,
    },
    validation: Object,
  },

  model: {
    prop: "vModel",
    event: "change",
  },

  computed: {
    question: {
      get() {
        return this.vModel;
      },

      set(v) {
        this.$emit("change", v);
      },
    },

    questionNo() {
      let questionNo = this.questionIndex + 1;

      if (this.topicIndex !== undefined) {
        const topicNo = this.topicIndex + 1;

        questionNo = `${topicNo}.${questionNo}`;
      }

      return questionNo;
    },
  },

  methods: {
    getQuestionTypeName(questionType) {
      let questionTypeName = "";

      if (questionType == "text") {
        questionTypeName = "พิมพ์ตอบ";
      }

      if (questionType == "choice") {
        questionTypeName = "เลือกตอบ";
      }

      if (questionType == "rating") {
        questionTypeName = "ให้คะแนน";
      }

      return questionTypeName;
    },

    addChoice() {
      this.question.question_choices.push({
        question_choice_no: this.question.question_choices.length + 1,
        question_choice_title: "",
      });
    },

    removeChoice(questionChoiceIndex) {
      this.question.question_choices.splice(questionChoiceIndex, 1);
    },
  },
};
</script>
