<template>
  <b-form @submit.stop.prevent>
    <div class="d-flex flex-row flex-wrap">
      <div class="col-8 px-2 mb-2">
        <label class="font-weight-bold" for="surveyTitle">ชื่อแบบประเมิน</label>

        <b-form-input
          id="surveyTitle"
          :state="invalid ? validation.form.survey_title.required : null"
          type="text"
          :disabled="disabled"
          v-model="form.survey_title"
        ></b-form-input>

        <b-form-invalid-feedback id="surveyTitle-feedback">
          กรุณากรอกชื่อแบบประเมิน
        </b-form-invalid-feedback>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold ft-s-14" for="surveyType"
          >ประเภทแบบประเมิน</label
        >
        <v-select
          id="surveyType"
          v-model="form.survey_type"
          :disabled="disabled"
          :options="surveyTypes"
        ></v-select>

        <small
          v-if="
            invalid &&
              validation.form.survey_type &&
              !validation.form.survey_type.required
          "
          class="text-danger"
        >
          กรุณาเลือกประเภทแบบประเมิน
        </small>
      </div>
    </div>

    <div class="d-flex flex-row mt-2">
      <div class="col-12 px-2">
        <label class="font-weight-bold ft-s-14" for="description"
          >รายละเอียดแบบประเมิน</label
        >
        <b-textarea
          name="description"
          id="description"
          :disabled="disabled"
          v-model="form.description"
        >
        </b-textarea>

        <!-- <small
          v-if="
            invalid &&
              validation.form.description &&
              !validation.form.description.required
          "
          class="text-danger"
        >
          กรุณาใส่รายละเอียด
        </small> -->
      </div>
    </div>

    <div class="d-flex flex-row flex-wrap">
      <b-col cols="12">
        <survey-question
          ref=""
          :invalid="invalid"
          :validation="validation.form.questions"
          :disabled="disabled"
          v-model="form.questions"
        ></survey-question>
      </b-col>
    </div>
  </b-form>
</template>

<script>
import vSelect from "vue-select";
// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
// import { quillEditor } from "vue-quill-editor";
import { Auth } from "../../models";
import SurveyQuestion from "./SurveyQuestion";

export default {
  props: {
    formData: Object,

    editData: {
      type: Object,
      default: () => {},
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabledInput: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "formData",
    event: "change",
  },

  components: {
    "v-select": vSelect,
    // quillEditor,
    SurveyQuestion,
  },

  data() {
    return {
      form: {
        id: this.editData.id || null,
        survey_title: this.editData.survey_title || "",
        survey_type: this.editData.survey_type || "",
        description: this.editData.description || "",
        questions:
          this.editData.questions ||
          [
            // {
            //   question_type: "choice",
            //   question_title:
            //     "ท่านมีโอกาสสังเกตการณ์สอนหลักสูตร D.A.R.E. ของตำรวจมากน้อยเพียงใด  ?(เลือกจำนวนร้อยละที่ใกล้เคียงที่สุด)",
            //   question_choices: [
            //     { question_choice_title: "100%" },
            //     { question_choice_title: "75%" },
            //     { question_choice_title: "50%" },
            //     { question_choice_title: "25%" },
            //     { question_choice_title: "0%" },
            //   ],
            // },
            // {
            //   question_type: "choice",
            //   question_title:
            //     "ท่านเห็นว่าเนื้อหาสาระของหลักสูตร D.A.R.E. สอดคล้องกับวัตถุประสงค์ของหลักสูตรมากน้อยเพียงใด?",
            //   question_choices: [
            //     { question_choice_title: "มาก" },
            //     { question_choice_title: "ค่อนข้างมาก" },
            //     { question_choice_title: "ปานกลาง" },
            //     { question_choice_title: "ค่อนข้างน้อย" },
            //     { question_choice_title: "น้อย" },
            //   ],
            // },
            // {
            //   question_type: "choice",
            //   question_title:
            //     "ท่านเห็นว่าสมุดแบบฝึกหัดสำหรับนักเรียน  ป.5 – ป.6  มีความเหมาะสมหรือไม่ ?",
            //   question_choices: [
            //     {
            //       question_choice_title:
            //         "เหมาะสมแล้ว   ( เลือกข้อนี้ข้ามไปตอบข้อ 5 เลย)",
            //     },
            //     {
            //       question_choice_title:
            //         "ยังไม่เหมาะสม   (เลือกข้อนี้กรุณาตอบข้อ 4  ด้วย)",
            //     },
            //   ],
            // },
            // {
            //   question_type: "text",
            //   question_title: "ควรปรับปรุงแบบฝึกหัดดังนี้",
            // },
            // {
            //   question_type: "topic",
            //   survey_topic_title:
            //     "ท่านมีความเห็นเกี่ยวกับการสอนของครูตำรวจ D.A.R.E. ในประเด็นนี้อย่างไร?",
            //   questions: [
            //     {
            //       question_type: "choice",
            //       question_title: "การเตรียมการ",
            //       question_choices: [
            //         { question_choice_title: "มาก" },
            //         { question_choice_title: "ค่อนข้างมาก" },
            //         { question_choice_title: "ปานกลาง" },
            //         { question_choice_title: "ค่อนข้างน้อย" },
            //         { question_choice_title: "น้อย" },
            //       ],
            //     },
            //     {
            //       question_type: "choice",
            //       question_title: "การนำเสนอเนื้อหา",
            //       question_choices: [
            //         { question_choice_title: "มาก" },
            //         { question_choice_title: "ค่อนข้างมาก" },
            //         { question_choice_title: "ปานกลาง" },
            //         { question_choice_title: "ค่อนข้างน้อย" },
            //         { question_choice_title: "น้อย" },
            //       ],
            //     },
            //     {
            //       question_type: "choice",
            //       question_title: "การใช้สื่อการสอน",
            //       question_choices: [
            //         { question_choice_title: "มาก" },
            //         { question_choice_title: "ค่อนข้างมาก" },
            //         { question_choice_title: "ปานกลาง" },
            //         { question_choice_title: "ค่อนข้างน้อย" },
            //         { question_choice_title: "น้อย" },
            //       ],
            //     },
            //     {
            //       question_type: "choice",
            //       question_title: "เทคนิคการสอน",
            //       question_choices: [
            //         { question_choice_title: "มาก" },
            //         { question_choice_title: "ค่อนข้างมาก" },
            //         { question_choice_title: "ปานกลาง" },
            //         { question_choice_title: "ค่อนข้างน้อย" },
            //         { question_choice_title: "น้อย" },
            //       ],
            //     },
            //     {
            //       question_type: "choice",
            //       question_title: "การตรงต่อเวลาความตั้งใจกระตือรือร้น",
            //       question_choices: [
            //         { question_choice_title: "มาก" },
            //         { question_choice_title: "ค่อนข้างมาก" },
            //         { question_choice_title: "ปานกลาง" },
            //         { question_choice_title: "ค่อนข้างน้อย" },
            //         { question_choice_title: "น้อย" },
            //       ],
            //     },
            //     {
            //       question_type: "choice",
            //       question_title: "มนุษยสัมพันธ์กับบุคลากรในโรงเรียน",
            //       question_choices: [
            //         { question_choice_title: "มาก" },
            //         { question_choice_title: "ค่อนข้างมาก" },
            //         { question_choice_title: "ปานกลาง" },
            //         { question_choice_title: "ค่อนข้างน้อย" },
            //         { question_choice_title: "น้อย" },
            //       ],
            //     },
            //   ],
            // },
            // {
            //   question_type: "choice",
            //   question_title:
            //     "ท่านเห็นว่าเด็กนักเรียนมีความสนใจกระตือรือร้นในการเรียนหลักสูตรนี้มากน้อยเพียงใด?",
            //   question_choices: [
            //     { question_choice_title: "มาก" },
            //     { question_choice_title: "ค่อนข้างมาก" },
            //     { question_choice_title: "ปานกลาง" },
            //     { question_choice_title: "ค่อนข้างน้อย" },
            //     { question_choice_title: "น้อย" },
            //   ],
            // },
            // {
            //   question_type: "choice",
            //   question_title:
            //     "ท่านคิดว่าหลักสูตรนี้เป็นประโยชน์ต่อเด็กนักเรียนมากน้อยเพียงใด?",
            //   question_choices: [
            //     { question_choice_title: "มาก" },
            //     { question_choice_title: "ค่อนข้างมาก" },
            //     { question_choice_title: "ปานกลาง" },
            //     { question_choice_title: "ค่อนข้างน้อย" },
            //     { question_choice_title: "น้อย" },
            //   ],
            // },
            // {
            //   question_type: "choice",
            //   question_title:
            //     "หลักสูตรนี้เป็นอุปสรรคกับการเรียนการสอนโดยปกติของโรงเรียนหรือไม่?",
            //   question_choices: [
            //     {
            //       question_choice_title:
            //         "ไม่เป็นอุปสรรค  (เลือกข้อนี้  ข้ามไปตอบ ข้อ  10  ได้เลย)",
            //     },
            //     {
            //       question_choice_title:
            //         "เป็นอุปสรรค  (เลือกข้อนี้ กรุณาตอบ  ข้อ  9  ด้วย)",
            //     },
            //   ],
            // },
            // {
            //   question_type: "text",
            //   question_title:
            //     "ถ้าเห็นว่าเป็นอุปสรรค กรุณาระบุว่าเป็นอุปสรรคอย่างไร?",
            // },
            // {
            //   question_type: "choice",
            //   question_title: "ท่านคิดว่าหลักสูตรนี้ควรมีต่อไปหรือไม่?",
            //   question_choices: [
            //     { question_choice_title: "ควรมีต่อไป" },
            //     { question_choice_title: "ไม่ควรมีต่อไป" },
            //   ],
            // },
            // {
            //   question_type: "text",
            //   question_title: "ท่านมีความเห็นเพิ่มเติมอย่างไรบ้าง?",
            // },
          ],
        // general_questions: [
        //   {
        //     question_no: 1,
        //     question_type: "text",
        //     question_title: "",
        //   },
        //   {
        //     question_no: 2,
        //     question_type: "choice",
        //     question_title: "",
        //     question_choices: [
        //       {
        //         question_choice_no: 1,
        //         question_choice_title: "",
        //       },
        //       {
        //         question_choice_no: 2,
        //         question_choice_title: "",
        //       },
        //     ],
        //   },
        //   {
        //     question_no: 3,
        //     question_type: "rating",
        //     question_title: "",
        //   },
        // ],
      },

      surveyTypes: [
        "แบบประเมินการเข้าสอน",
        "แบบประเมินผล(ครูประจำชั้น)",
        "แบบประเมินผล(นักเรียน)",
        "แบบประเมินผล(ผู้ปกครอง)",
      ],

      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
        placeholder: "รายละเอียด",
      },
    };
  },

  watch: {
    form: {
      deep: true,
      immediate: true,
      handler: "syncData",
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  methods: {
    syncData(v) {
      this.$emit("change", v);
    },
  },
};
</script>
